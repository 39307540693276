<template>
    <div style="text-align: left;">
        <div class="df-jc-s-b boxs">
            <div class="p-30 box">
                <div class="df-jc-s-b">
                    <div class=""><span class="c-1 bold">累计佣金</span><span class="f12 c-2">(单位：元)</span></div>
                    <div class="f14 c-1 pointer" @click="$oucy.go('/acc/distribution/brokerage')">查看明细<i class="el-icon-arrow-right"></i></div>
                </div>
                <div class="df-jc-s-b m-t-30 df-ai-c ">
                    <div class="f32 bold c-1">
                        <span v-if="view">￥{{prlductData.userTotalCommission}}</span>
                        <span v-else>****************</span>
                    </div>
                    <div class="c-2">
                        <img src="@/assets/icon/view.png" class="pointer" alt="" v-if="view" @click="view=!view">
                        <img src="@/assets/icon/notview.png" class="pointer" alt="" v-else @click="view=!view">
                    </div>
                </div>
            </div>
            <div class="p-30 box">
                <div class="df-jc-s-b">
                    <div class=""><span class="c-1 bold">未分成佣金</span><span class="f12 c-2">(单位：元)</span></div>
                </div>
                <div class="df-jc-s-b m-t-30 df-ai-c">
                    <div class="f32 bold c-1">
                        <span v-if="view1">￥{{prlductData.userOffCommission}}</span>
                        <span v-else>****************</span>
                    </div>
                    <div class="c-2">
                        <img src="@/assets/icon/view.png" class="pointer" alt="" v-if="view1" @click="view1=!view1">
                        <img src="@/assets/icon/notview.png" class="pointer" alt="" v-else @click="view1=!view1">
                    </div>
                </div>
            </div>
            <div class="p-30 box">
                <div class="df-jc-s-b">
                    <div class=""><span class="c-1 bold">已分成佣金</span><span class="f12 c-2">(单位：元)</span></div>
                    <div class="f14 c-1 pointer" @click="$oucy.go('/acc/billDetail?type=6')">转出记录<i class="el-icon-arrow-right"></i></div>
                </div>
                <div class="df-jc-s-b m-t-30 df-ai-c">
                    <div class="f32 bold c-1">
                        <span v-if="view2">￥{{prlductData.userCommission}}</span>
                        <span v-else>****************</span>
                    </div>
                    <div class="c-2">
                        <img src="@/assets/icon/view.png" class="pointer" alt="" v-if="view2" @click="view2=!view2">
                        <img src="@/assets/icon/notview.png" class="pointer" alt="" v-else @click="view2=!view2">
                    </div>
                </div>
                <div class="m-t-30">
                    <el-button round size="mini" type="primary" plain @click="$oucy.go('/acc/distribution/GotoBalance')">转到余额</el-button>
                </div>
            </div>
        </div>
        <div class="m-t-10 qita">
            <div class="f20 bold c-1">其他功能</div>
            <div class="qitaBoxs df m-t-30">
                <div class="qitaBox" v-if="0"><img src="@/assets/images/distribution/shop.png" alt="">
                    <div>分销店铺</div>
                </div>
                <div class="qitaBox" @click="$oucy.go('/acc/distribution/team')"><img src="@/assets/images/distribution/team.png" alt="">
                    <div>我的团队</div>
                </div>
                <div class="qitaBox" @click="generateDistributionPoster"><img src="@/assets/images/distribution/card.png" alt="">
                    <div>分享海报</div>
                </div>
            </div>
        </div>
<!--         <div class="image__preview" v-if="show" @click="show=false">
    <div>
        <el-image style="height: 80vh;width: auto;" :src="url" @click.stop="download(url)">
        </el-image>
        <div class="text-center">
            <el-button-group>
                <el-button :type="posterType==1?'primary':''" @click.stop="posterType=1,generateDistributionPoster()">样式1</el-button>
                <el-button :type="posterType==2?'primary':''" @click.stop="posterType=2,generateDistributionPoster()">样式2</el-button>
                <el-button :type="posterType==3?'primary':''" @click.stop="posterType=3,generateDistributionPoster()">样式3</el-button>
            </el-button-group>
        </div>
    </div>
</div> -->
<sharePoster ref="sharePoster"></sharePoster>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { usercommission, poster } from "@/service"
export default {
    name: "EditUser",
    components: {},
    data() {
        return {
            view: false,
            view1: false,
            view2: false,
            show: false,
            posterType: 1,
            url: null,
            urls: {},
            prlductData: {},
        }
    },
    mounted() {
        this.getUserCommission()
    },
    methods: {


        // 明细
        getUserCommission() {
            usercommission.getUserCommission({

            }).then(res => {
                this.prlductData = res
            })
        },

        // 分享海报
        generateDistributionPoster() {
            let loginUser = localGet(this.$oucy.userInfoKey)
            let obj = {
                objectId: loginUser.id||'0',
                playbillCategory: 1,
                redirectUrlScene:{}
            };
            this.$refs.sharePoster.show(obj)
        },
        download(url = this.url) {
            window.open(encodeURI(url), '_blank')
            this.show = false
            return
            let a = document.createElement("a");
            a.download = `${new Date().toLocaleString()}海报`;
            a.setAttribute('href', url)
            a.click();

        },

    }
}
</script>
<style scoped lang="less">

.boxs {
    margin-left: -10px;
    margin-right: -10px;

    .box {
        background: #fff;
        flex: 1;
        margin: 0 10px;
    }
}

.qita {
    background: #fff;
    height: 420px;
    padding: 30px;
}

.qitaBox {
    cursor: pointer;
    text-align: center;
    padding: 20px;
    border-radius: 16px 16px 16px 16px;
    font-size: 14px;
    font-family: MicrosoftYaHei-, MicrosoftYaHei;
    font-weight: normal;
    color: #555C68;
    margin-right: 10px;
    background: #F8FBFC;

    &:hover {
        background: #fff;
        box-shadow: 0px 2px 20px 1px rgba(168, 181, 200, 0.23921568627450981);

    }
}
</style>